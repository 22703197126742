import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Particles from "../components/particles"
import React from "react"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: `404` })} />
    <div
      style={{
        height: "calc(100vh - 97px)",
        backgroundColor: "var(--color-background)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Particles />
      <h1 style={{ textAlign: "center" }}>
        <FormattedMessage id="notfound" />
      </h1>
    </div>
  </Layout>
)

export default injectIntl(NotFoundPage)
